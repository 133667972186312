
import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  type: PropTypes.string,
  path: PropTypes.string,
  share: PropTypes.string
}

const defaultProps =  {
  lang: `ja`,
  meta: [],
}

const SEO = ({ description, lang, meta, title, type, path, share }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            social {
              shareImage
            }
          }
        }
      }
    `
  )
  
  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const pageTitle = title ? `${title} | ${defaultTitle}` : defaultTitle
  const shareImage = share ? `${site.siteMetadata?.siteUrl}${share}` : site.siteMetadata?.social.shareImage
  const pageType = type || `article`
  const pageUrl = path ? `${site.siteMetadata?.siteUrl}${path}` : site.siteMetadata?.siteUrl

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: pageUrl,
        },
        {
          property: `og:title`,
          content: pageTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: pageType,
        },
        {
          property: `og:image`,
          content: shareImage,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
      ].concat(meta)}
      link={[
        {
          rel: `canonical`,
          href: pageUrl
        }
      ]}
    />
  )
}

SEO.defaultProps = defaultProps
SEO.propTypes = propTypes

export default SEO
