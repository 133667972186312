import { useState, useEffect,useCallback } from 'react'
import { breakpoint } from '@const/config'

/**
 * @returns
 * @property {Number} width - window width
 * @property {Number} height - window height
 * @property {Object} device - check deveice size
 * @property {Boolean} device.isPC - is pc size
 * @property {Boolean} device.isTab - is tablet size
 * @property {Boolean} device.isMobile - is mobile size
 */
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
    device: {
      isPc: null,
      isTab: null,
      isMobile: null,
    }
  })

  const handleResize = useCallback(() => {
    const winW = window.innerWidth
    const winH = window.innerHeight
    setWindowSize({
      width: winW,
      height: winH,
      device: {
        isPc: winW > breakpoint.tab,
        isTab: winW > breakpoint.mobile && winW < breakpoint.tab,
        isMobile: winW < breakpoint.mobile,
      }
    });
  },[])
  
  useEffect( () => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  },[handleResize])

  return windowSize
}