import React from "react"
// import PropTypes from "prop-types"
import classNames from 'classnames/bind';
import styles from '@styles/components/footer.module.scss'


import IcnFb from '@comp/atom/icn_fb'
import IcnTw from '@comp/atom/icn_tw'
import BtnDetail from '@comp/btn_detail'
import FooterNav from '@comp/footer_nav'

const cx = classNames.bind(styles);
const propTypes = {
}

const Footer = () => {
  const classNameFooter = cx({
    wrap: true,
  })
  return (
    <>
      <footer className={classNameFooter}>
        <div className={styles.inner}>
          <FooterNav />
          <div className={styles.links}>
            <p className={styles.policy}>
              <BtnDetail
                to={'/privacypolicy'}
                isTxtLink={true}
                className="isFooter"
              >
                <span className={styles.policy__inner}>プライバシーポリシー</span>
              </BtnDetail>
            </p>
            <ul className={styles.social}>
              <li className={styles.social__fb}>
                <a href="https://www.facebook.com/sixstarsake/" target="_blank" aria-label="Facebook" rel="noopener noreferrer"><IcnFb /></a>
              </li>
              <li className={styles.social__tw}>
                <a href="https://twitter.com/misaishuhan" target="_blank" aria-label="Twitter" rel="noopener noreferrer"><IcnTw /></a>
              </li>
            </ul>
          </div>
          <p className={styles.copyright}>© 2021 MIRAI SAKE COMPANY All rights reserved.</p>
        </div>
      </footer>
    </>
  )
}

Footer.propTypes = propTypes
export default Footer
