import React from "react"
import styles from '@styles/components/footer_nav.module.scss'

import TransitionAnimationLink from '@comp/transition_animation_link'
import Logo from '@comp/atom/logo'
import IcnTriangle from '@comp/atom/icn_triangle'

const propTypes = {
}

const FooterNav = () => {
  return (
    <>
      <nav className={styles.wrap}>
        <div className={styles.inner}>
          <h1 className={styles.logo}>
            <TransitionAnimationLink to={'/'}><Logo /></TransitionAnimationLink>
          </h1>
          <ul className={styles.list}>
              <li className={styles.list__itm}>
                <TransitionAnimationLink to={'/news/'} className={styles.list__link}>
                  <span className={styles.list__label}>NEWS</span>
                  <span className={styles.list__icn}><IcnTriangle /></span>
                </TransitionAnimationLink>
              </li>
              <li className={styles.list__itm}>
                <TransitionAnimationLink to={'/project/'} className={styles.list__link}>
                  <span className={styles.list__label}>PROJECT</span>
                  <span className={styles.list__icn}><IcnTriangle /></span>
                </TransitionAnimationLink>
              </li>
              <li className={styles.list__itm}>
                <TransitionAnimationLink to={'/philosophy/'} className={styles.list__link}>
                  <span className={styles.list__label}>PHILOSOPHY</span>
                  <span className={styles.list__icn}><IcnTriangle /></span>
                </TransitionAnimationLink>
              </li>
              <li className={styles.list__itm}>
                <TransitionAnimationLink to={'/member/'} className={styles.list__link}>
                  <span className={styles.list__label}>MEMBER</span>
                  <span className={styles.list__icn}><IcnTriangle /></span>
                </TransitionAnimationLink>
              </li>
              <li className={styles.list__itm}>
                <TransitionAnimationLink to={'/company/'} className={styles.list__link}>
                  <span className={styles.list__label}>COMPANY</span>
                  <span className={styles.list__icn}><IcnTriangle /></span>
                </TransitionAnimationLink>
              </li>
              <li className={styles.list__itm}>
                <TransitionAnimationLink to={'/contact/'} className={styles.list__link}>
                  <span className={styles.list__label}>CONTACT</span>
                  <span className={styles.list__icn}><IcnTriangle /></span>
                </TransitionAnimationLink>
              </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

FooterNav.propTypes = propTypes
export default FooterNav
