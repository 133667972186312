import React from "react"

import PropTypes from "prop-types"
import classNames from 'classnames/bind'
import styles from '@styles/components/global_nav.module.scss'

import TransitionAnimationLink from '@comp/transition_animation_link'
import IcnTriangle from '@comp/atom/icn_triangle'

const cx = classNames.bind(styles);
const propTypes = {
  id: PropTypes.string,
  wrapClass: PropTypes.string,
  isMenuOpen: PropTypes.bool.isRequired,
  style: PropTypes.object
}

const GlobalNav = ({
  id,
  wrapClass,
  isMenuOpen,
  style,
}) => {
  const classNameGlobalNav = cx({
    wrap: true,
    [wrapClass] :wrapClass ? wrapClass :null
  })
  const classNameNavItmCol2 = cx({
    list__itm: true,
    isCol2: true
  })

  return (
    <>
      <nav id={id} className={classNameGlobalNav} aria-hidden={!isMenuOpen}>
        <div className={styles.contents} style={style}>
          <div className={styles.inner}>
            <ul className={styles.list}>
              <li className={styles.list__itm}>
                <TransitionAnimationLink to={'/news/'} className={styles.list__link}>
                  <span className={styles.list__label}>取り組み</span>
                  <span className={styles.list__label__en}>NEWS</span>
                  <span className={styles.list__icn}><IcnTriangle /></span>
                </TransitionAnimationLink>
              </li>
              <li className={styles.list__itm}>
                <TransitionAnimationLink to={'/project/'} className={styles.list__link}>
                  <span className={styles.list__label}>事業内容</span>
                  <span className={styles.list__label__en}>PROJECT</span>
                  <span className={styles.list__icn}><IcnTriangle /></span>
                </TransitionAnimationLink>
              </li>
              <li className={styles.list__itm}>
                <TransitionAnimationLink to={'/philosophy/'} className={styles.list__link}>
                  <span className={styles.list__label}>企業理念</span>
                  <span className={styles.list__label__en}>PHILOSOPHY</span>
                  <span className={styles.list__icn}><IcnTriangle /></span>
                </TransitionAnimationLink>
              </li>
              <li className={styles.list__itm}>
                <TransitionAnimationLink to={'/member/'} className={styles.list__link}>
                  <span className={styles.list__label}>メンバー</span>
                  <span className={styles.list__label__en}>MEMBER</span>
                  <span className={styles.list__icn}><IcnTriangle /></span>
                </TransitionAnimationLink>
              </li>
              <li className={classNameNavItmCol2}>
                <TransitionAnimationLink to={'/company/'} className={styles.list__link}>
                  <span className={styles.list__label}>会社概要</span>
                  <span className={styles.list__label__en}>COMPANY</span>
                  <span className={styles.list__icn}><IcnTriangle /></span>
                </TransitionAnimationLink>
              </li>
              <li className={classNameNavItmCol2}>
                <TransitionAnimationLink to={'/contact/'} className={styles.list__link}>
                  <span className={styles.list__label}>お問い合わせ</span>
                  <span className={styles.list__label__en}>CONTACT</span>
                  <span className={styles.list__icn}><IcnTriangle /></span>
                </TransitionAnimationLink>
              </li>
            </ul>

            {/* <ul className={styles.lang}>
              <li className={styles.lang__itm}>
                <a href="/" className={styles.lang__btn}>JP</a>
              </li>
              <li className={styles.lang__itm}>
                <a href="/" className={styles.lang__btn}>EN</a>
              </li>
            </ul> */}
          </div>
        </div>
      </nav>
    </>
  )
}

GlobalNav.propTypes = propTypes
export default GlobalNav
