import React, { useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import classNames from 'classnames/bind';
import styles from '@styles/components/header.module.scss'


import TransitionAnimationLink from '@comp/transition_animation_link'
import GlobalNav from '@comp/global_nav'
import Logo from '@comp/atom/logo'

import { useWindowSize } from '@/hooks/use_window_size'

const cx = classNames.bind(styles);
const propTypes = {
  pageName: PropTypes.string.isRequired
}

const Header = ({ pageName, status }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [scrollBarWidth,setScrollBarWidth] = useState(15)
  const {device} = useWindowSize()

  const switchScroll = useCallback( (disabled) => {
    document.body.style.paddingRight = disabled ? `${scrollBarWidth}px`: null
    document.documentElement.style.overflow = disabled ? 'hidden': null
    document.documentElement.style.touchAction = disabled ? 'none': null
  },[scrollBarWidth])

  const onClickMenu = useCallback( (evt) => {
    evt.preventDefault()
    switchScroll(!isMenuOpen)
    setIsMenuOpen(!isMenuOpen)
  },[isMenuOpen, switchScroll])

  const classNameHeader = useMemo( () => {
    return cx({
      wrap: true,
      isHome: pageName === 'home',
      isMenuOpen: isMenuOpen
    })
  },[isMenuOpen, pageName])

  useEffect( () => {
    setScrollBarWidth(window.innerWidth - document.body.clientWidth)
    if(device.isPc){
      switchScroll(false)
      setIsMenuOpen(false)
    }
  },[device, switchScroll])

  useEffect( () => {
    return () => {
      switchScroll(false)
    }
  },[switchScroll])

  return (
    <>
      <header className={classNameHeader} style={{'paddingRight': isMenuOpen ? `${scrollBarWidth}px`: null}} data-status={status}>
        <div className={styles.inner}>
          <div className={styles.head}>
            <h1 className={styles.logo}>
              <TransitionAnimationLink to={'/'}><Logo /></TransitionAnimationLink>
            </h1>
            <div className={styles.btnMenu}>
              <a href='#' onClick={onClickMenu} className={styles.btnMenu__link} aria-expanded={isMenuOpen} aria-controls="global-nav">
                <span className={styles.btnMenu__line01}></span>
                <span className={styles.btnMenu__line02}></span>
                <span className={styles.btnMenu__line03}></span>
              </a>
            </div>
          </div>
          <GlobalNav id="global-nav" wrapClass={styles.nav} isMenuOpen={isMenuOpen} style={{'paddingRight': isMenuOpen ? `${scrollBarWidth}px`: null}} />
        </div>
      </header>
    </>
  )
}

Header.propTypes = propTypes
export default Header
