import React from "react"
import styles from '@styles/components/atom.module.scss'
const IcnTriangle = ({
  color
}) => {
  const fillColor = styles[`fill__${color}`] ? styles[`fill__${color}`] : styles['fill__black']
  return (
    <svg className={fillColor} viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg" role="presentation">
      <path d="M10 0.5V10.5H0L10 0.5Z"/>
    </svg>
  )
}

export default IcnTriangle
