import React from 'react';
import TransitionLink from "gatsby-plugin-transition-link";

import PropTypes from "prop-types"

const propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string
}

const TransitionAnimationLink = ({
  children,
  to,
  onClick,
  className
}) => {
  return (
    <>
      <TransitionLink
        className={className}
        to={to}
        exit={{ 
          length: 1.5,
          delay: 0.3
        }}
        entry={{
          length: 0.3,
          delay: 1.5
        }}
        onClick={onClick}
      >
        { children }
      </TransitionLink>
    </>
  )
}

TransitionAnimationLink.propTypes = propTypes
export default TransitionAnimationLink