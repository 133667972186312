import { useState, useEffect,　useCallback } from 'react'


let state = {
  x:0,
  y:0
}

/**
 * @returns
 * @property {Number} x - window clientX
 * @property {Number} y - window clientY
 */
export function usePointerPosition() {
  const [pointerPosition, setPointerPosition] = useState(state)
  state = pointerPosition

  const handleEvents = useCallback((e) => {
    setPointerPosition({
      x: e.clientX,
      y: e.clientY
    });
  },[])
  
  useEffect( () => {
    window.addEventListener('mousedown', handleEvents)
    return () => {
      window.removeEventListener('mousedown', handleEvents)
    }
  },[handleEvents, pointerPosition])

  return pointerPosition
}