
import PropTypes from "prop-types"

const propTypes = {
  uniqueClassName: PropTypes.array | null,
  styles: PropTypes.object.isRequired,
}

const additionalClassNames = ({
  uniqueClassName,
  styles,
}) => {
  return uniqueClassName
    ? uniqueClassName.map(name => {
        const style = styles[name];
        return style ? style : name;
      })
    : [];
};

additionalClassNames.propTypes = propTypes;
export default additionalClassNames;
