import React from "react"

const IcnTw = () => {
  const title = 'Twitter'
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby={title}>
      <title>{title}</title>
      <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="black"/>
      <path d="M9.8047 18.3294C15.1255 18.3294 18.0355 13.9212 18.0355 10.0986C18.0355 9.97321 18.0331 9.84841 18.0271 9.72481C18.5917 9.31621 19.0831 8.80681 19.4701 8.22721C18.9517 8.45761 18.3937 8.61241 17.8087 8.68261C18.4057 8.32441 18.8647 7.75801 19.0807 7.08241C18.5215 7.41361 17.9029 7.65481 17.2441 7.78441C16.7161 7.22221 15.9643 6.87061 15.1327 6.87061C13.5349 6.87061 12.2395 8.16601 12.2395 9.76321C12.2395 9.99001 12.2647 10.2108 12.3145 10.4226C9.9103 10.302 7.7785 9.15061 6.3517 7.40041C6.1033 7.82761 5.9599 8.32441 5.9599 8.85421C5.9599 9.85801 6.4705 10.7436 7.2475 11.262C6.7729 11.2476 6.3271 11.1168 5.9371 10.9002C5.9365 10.9122 5.9365 10.9242 5.9365 10.9368C5.9365 12.3378 6.9337 13.5078 8.2573 13.773C8.0143 13.839 7.7587 13.875 7.4947 13.875C7.3081 13.875 7.1269 13.8564 6.9511 13.8228C7.3195 14.9718 8.3875 15.8082 9.6535 15.8322C8.6635 16.608 7.4161 17.0706 6.0607 17.0706C5.8273 17.0706 5.5969 17.0574 5.3707 17.0304C6.6505 17.8506 8.1703 18.3294 9.8047 18.3294Z" fill="white"/>
    </svg>
    
  )
}

export default IcnTw
