
import React, { useMemo }  from "react"
import { TransitionPortal,TransitionState } from "gatsby-plugin-transition-link"

import styles from '@styles/components/transition_cover.module.scss'

import {usePointerPosition} from '@/hooks/use_pointer_position'
import {useWindowSize} from "@/hooks/use_window_size"

const TransitionCover = () => {
  const pointerPosition = usePointerPosition()
  const {width,height} = useWindowSize()

  const top = useMemo( () => {
    return pointerPosition.y - (height/2)
  },[height, pointerPosition.y])
  const left = useMemo(() => {
    return pointerPosition.x - (width/2) 
  },[pointerPosition.x, width])

  return (
    <TransitionPortal>
      <TransitionState>
        {({ mount, transitionStatus }) => {
          return (
            <div className={styles.wrap} data-status={transitionStatus} aria-hidden="true">
              <div className={styles.inner} style={{
                top: top ? top : 0,
                left: left ? left: 0
              }}></div>
            </div>
          )
        }}
      </TransitionState>
    </TransitionPortal>
  )
}

export default TransitionCover