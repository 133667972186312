
import React, { useMemo } from "react"
import PropTypes from "prop-types"
import classNames from 'classnames/bind'
import Helmet from "react-helmet"

// global styles
import 'ress'
import '@styles/styles.scss'

// layout styles
import styles from '@styles/components/layout.module.scss'

import Header from '@comp/header'
import Footer from '@comp/footer'
import TransitionCover from '@comp/transition_cover'

import additionalClassNames from '@comp/util/additionalClassNames'

const cx = classNames.bind(styles);

const propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string.isRequired,
  classList: PropTypes.array,
  headerStatus: PropTypes.string
}

const Layout = ({ children, location, pageName, classList, headerStatus }) => {

  const addItonalClass = useMemo( () => {
    return additionalClassNames({
      uniqueClassName: classList,
      styles
    })
  },[classList])


  const classNameLayout = useMemo( () => {
    return cx({
      wrapper: true,
      isHome: pageName === 'home'
    },addItonalClass)
  },[addItonalClass, pageName])


  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Noto+Sans+JP:wght@700&display=swap" rel="stylesheet"></link>
      </Helmet>
      <div className={classNameLayout}>
        <div className={styles.inner}>
          <Header pageName={pageName} status={headerStatus} />
          {children}
        </div>
        <Footer />
      </div>
      <TransitionCover />
    </>
  )
}

Layout.propTypes = propTypes
export default Layout
