import React from "react"
import styles from '@styles/components/atom.module.scss'

const Logo = ({
  color
}) => {
  const fillColor = styles[`fill__${color}`] ? styles[`fill__${color}`] : styles['fill__black']
  const title = 'MIRAI SAKE COMPANY'
  return (
    <svg viewBox="0 0 138 33" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby={title} className={fillColor}>
      <title>{title}</title>
      <path d="M0 31.6671L1.45775 24.15L3.88732 29.5777L6.35577 24.15L7.67261 31.6671H6.58416L5.86986 27.4494L3.83873 31.9927L1.82218 27.4396L1.08845 31.6671H0Z"/>
      <path d="M12.4346 24.6748V31.6671H11.3656V24.6748H12.4346Z"/>
      <path d="M17.3764 24.6748C18.0193 24.6165 18.6644 24.7591 19.2228 25.083C19.4854 25.2673 19.6982 25.5137 19.8423 25.8003C19.9865 26.0869 20.0574 26.4047 20.0489 26.7253C20.0641 27.1808 19.9164 27.6267 19.6323 27.983C19.3481 28.3393 18.9462 28.5825 18.4988 28.669L20.6806 31.6574H19.3881L17.3861 28.7953H17.1966V31.6574H16.1276V24.6748H17.3764ZM17.1966 27.8818H17.5319C17.8283 27.8818 19.0236 27.8478 19.0236 26.7399C19.0236 25.7681 18.0907 25.6612 17.5659 25.6612H17.1966V27.8818Z"/>
      <path d="M27.8527 29.9761H24.8643L24.0966 31.6671H22.9449L26.4241 24.2666L29.738 31.6671H28.5864L27.8527 29.9761ZM27.4202 28.9703L26.3852 26.6039L25.3065 28.9703H27.4202Z"/>
      <path d="M33.7031 24.6748V31.6671H32.6341V24.6748H33.7031Z"/>
      <path d="M45.2922 26.2394C45.2224 26.0697 45.1143 25.9183 44.9764 25.7973C44.7644 25.6198 44.4946 25.5264 44.2183 25.5349C44.0985 25.5199 43.9769 25.5302 43.8613 25.5651C43.7457 25.5999 43.6387 25.6586 43.5472 25.7373C43.4556 25.8161 43.3816 25.9131 43.3298 26.0222C43.278 26.1312 43.2496 26.2499 43.2465 26.3706C43.2465 26.5796 43.3194 26.9489 43.9997 27.221L44.7043 27.5028C45.9919 28.0276 46.3515 28.7613 46.3515 29.5582C46.364 29.8572 46.3141 30.1555 46.205 30.4341C46.096 30.7128 45.9302 30.9657 45.7182 31.1768C45.5061 31.388 45.2525 31.5527 44.9734 31.6605C44.6943 31.7683 44.3958 31.8169 44.0969 31.8032C43.7852 31.8199 43.4739 31.7651 43.1867 31.643C42.8995 31.5209 42.644 31.3346 42.4399 31.0986C42.0965 30.7241 41.8894 30.2448 41.8519 29.738L42.9112 29.5048C42.9125 29.826 43.0145 30.1387 43.2028 30.3989C43.3111 30.5359 43.4504 30.6452 43.6092 30.7179C43.768 30.7905 43.9418 30.8244 44.1163 30.8168C44.271 30.8209 44.4248 30.7932 44.5683 30.7353C44.7118 30.6774 44.8418 30.5906 44.9503 30.4803C45.0588 30.37 45.1434 30.2386 45.1989 30.0941C45.2543 29.9497 45.2795 29.7954 45.2728 29.6408C45.2728 28.9314 44.7237 28.635 44.2621 28.4455L43.5915 28.1831C43.0181 27.945 42.1678 27.4591 42.1678 26.4144C42.1695 26.1539 42.2251 25.8966 42.3312 25.6587C42.4373 25.4208 42.5915 25.2074 42.7842 25.0321C42.9769 24.8568 43.2038 24.7233 43.4506 24.6401C43.6974 24.5568 43.9588 24.5256 44.2183 24.5485C44.7305 24.5306 45.231 24.7038 45.6226 25.0344C45.8451 25.2276 46.0282 25.4619 46.162 25.7244L45.2922 26.2394Z"/>
      <path d="M53.8054 29.9761H50.817L50.0493 31.6671H48.8928L52.3768 24.2666L55.6859 31.6671H54.5343L53.8054 29.9761ZM53.3778 28.9703L52.338 26.6039L51.2592 28.9703H53.3778Z"/>
      <path d="M59.4469 27.4639L62.1534 24.6748H63.5577L60.4041 27.8381L63.5772 31.6671H62.1534L59.6607 28.5621L59.4469 28.771V31.6865H58.3779V24.6748H59.4469V27.4639Z"/>
      <path d="M70.6084 25.6806H67.8192V27.4494H70.5209V28.4601H67.8192V30.6613H70.6084V31.6671H66.7502V24.6748H70.6084V25.6806Z"/>
      <path d="M83.6406 26.3075C83.1327 25.8144 82.4534 25.5375 81.7456 25.5349C81.4008 25.5282 81.0585 25.593 80.74 25.7251C80.4216 25.8572 80.1339 26.0539 79.8952 26.3027C79.6565 26.5514 79.4718 26.8469 79.3529 27.1706C79.234 27.4942 79.1834 27.8389 79.2042 28.1831C79.191 28.5257 79.2477 28.8673 79.3709 29.1872C79.4942 29.5071 79.6814 29.7985 79.921 30.0437C80.1607 30.2888 80.4478 30.4825 80.7649 30.6129C81.0819 30.7433 81.4222 30.8077 81.765 30.8022C82.4735 30.7844 83.1482 30.4957 83.6503 29.9956V31.2638C83.0679 31.6046 82.4058 31.7856 81.731 31.7886C80.8107 31.8031 79.9219 31.4538 79.2577 30.8168C78.8886 30.4866 78.5961 30.0799 78.4006 29.625C78.205 29.1701 78.1111 28.678 78.1255 28.1831C78.111 27.674 78.2035 27.1675 78.3968 26.6963C78.5901 26.2251 78.8801 25.7996 79.248 25.4474C79.5868 25.1341 79.9849 24.8918 80.4189 24.7349C80.8529 24.578 81.3139 24.5097 81.7747 24.5339C82.4309 24.5094 83.0798 24.6783 83.6406 25.0198V26.3075Z"/>
      <path d="M94.1267 28.1831C94.1296 28.9035 93.9186 29.6086 93.5204 30.2091C93.1223 30.8095 92.5549 31.2782 91.8901 31.5559C91.2253 31.8336 90.4931 31.9078 89.7861 31.769C89.0792 31.6302 88.4293 31.2848 87.9189 30.7764C87.4084 30.2679 87.0603 29.6195 86.9187 28.9131C86.7771 28.2067 86.8483 27.4742 87.1234 26.8083C87.3984 26.1424 87.8649 25.5731 88.4637 25.1726C89.0625 24.772 89.7668 24.5582 90.4872 24.5582C90.9673 24.5456 91.4451 24.6306 91.8914 24.8081C92.3377 24.9856 92.7433 25.2519 93.0836 25.5909C93.4239 25.9298 93.6918 26.3344 93.8712 26.7799C94.0505 27.2255 94.1374 27.7029 94.1267 28.1831ZM93.0577 28.1831C93.0434 27.6781 92.8805 27.1884 92.5895 26.7754C92.2985 26.3624 91.8922 26.0443 91.4214 25.8609C90.9507 25.6774 90.4363 25.6368 89.9425 25.7441C89.4488 25.8513 88.9976 26.1017 88.6454 26.464C88.2932 26.8262 88.0555 27.2842 87.9621 27.7807C87.8686 28.2773 87.9236 28.7903 88.1201 29.2558C88.3167 29.7212 88.646 30.1185 89.067 30.3978C89.488 30.6772 89.9819 30.8263 90.4872 30.8265C90.8337 30.8365 91.1785 30.7743 91.4997 30.6441C91.821 30.5138 92.1117 30.3182 92.3533 30.0697C92.595 29.8211 92.7824 29.5251 92.9036 29.2004C93.0249 28.8756 93.0774 28.5292 93.0577 28.1831Z"/>
      <path d="M96.8575 31.6671L98.3153 24.15L100.745 29.5777L103.208 24.15L104.53 31.6671H103.442L102.727 27.4494L100.691 31.9927L98.6797 27.4396L97.946 31.6671H96.8575Z"/>
      <path d="M109.554 24.6748C110.09 24.6467 110.623 24.7768 111.085 25.0489C111.359 25.239 111.581 25.4937 111.733 25.7904C111.885 26.087 111.961 26.4165 111.955 26.7496C111.963 27.0393 111.906 27.3272 111.788 27.592C111.67 27.8568 111.495 28.092 111.275 28.2803C110.79 28.6649 110.182 28.8585 109.564 28.8245H109.078V31.6671H108.009V24.6748H109.554ZM109.069 27.8381H109.554C110.478 27.8381 110.92 27.391 110.92 26.7399C110.92 26.3512 110.76 25.6612 109.545 25.6612H109.059L109.069 27.8381Z"/>
      <path d="M118.753 29.9761H115.765L114.997 31.6671H113.845L117.324 24.2666L120.638 31.6671H119.487L118.753 29.9761ZM118.32 28.9703L117.285 26.6039L116.202 28.9703H118.32Z"/>
      <path d="M123.116 31.6671V24.2035L128.189 29.5194V24.6602H129.254V32.0947L124.185 26.7788V31.6671H123.116Z"/>
      <path d="M134.599 28.669L132.305 24.6991H133.52L135.133 27.498L136.746 24.6991H138L135.687 28.669V31.6914H134.599V28.669Z"/>
      <path d="M30.8799 11.3024H32.2745L31.1472 12.211L31.5894 13.6688L30.4329 12.7796L29.291 13.6785L29.7332 12.2208L28.5961 11.3218H29.9907L30.428 9.86407L30.8799 11.3024Z"/>
      <path d="M46.2203 4.46071H47.6197L46.4924 5.34994L46.9346 6.80768L45.7733 5.93303L44.6314 6.83684L45.0735 5.37909L43.9316 4.48015H45.3262L45.7684 3.0224L46.2203 4.46071Z"/>
      <path d="M62.7171 1.45775H64.1117L62.9844 2.34697L63.4266 3.80472L62.2701 2.91549L61.1282 3.83387L61.5704 2.37613L60.4285 1.45775H61.823L62.2604 0L62.7171 1.45775Z"/>
      <path d="M79.0147 1.45775H80.4093L79.282 2.34697L79.7242 3.80472L78.5677 2.91549L77.4258 3.8193L77.868 2.36155L76.7261 1.45775H78.1207L78.558 0L79.0147 1.45775Z"/>
      <path d="M95.5115 4.46071H96.9061L95.7788 5.34993L96.2161 6.80768L95.0742 5.90388L93.9275 6.80768L94.3696 5.34993L93.2423 4.46071H94.6369L95.0742 3.00296L95.5115 4.46071Z"/>
      <path d="M110.847 11.3024H112.247L111.114 12.1916L111.557 13.6494L110.415 12.7504L109.268 13.6494L109.71 12.1916L108.583 11.3024H109.977L110.415 9.84467L110.847 11.3024Z"/>
    </svg>
  )
}

export default Logo
