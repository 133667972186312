import React from "react"

const IcnFb = () => {
  const title = 'Facebook'
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby={title}>
      <title>{title}</title>
      <path d="M24 12C24 5.3712 18.6288 0 12 0C5.3712 0 0 5.3712 0 12C0 17.9904 4.3872 22.9536 10.1232 23.856V15.4704H7.0752V12H10.1232V9.3552C10.1232 6.3456 11.9136 4.6848 14.6544 4.6848C15.9696 4.6848 17.3424 4.92 17.3424 4.92V7.872H15.8304C14.3376 7.872 13.8768 8.7984 13.8768 9.744V12H17.2032L16.6704 15.4704H13.872V23.856C19.6128 22.9536 24 17.9904 24 12Z" fill="black"/>
      <path d="M16.6704 15.4707L17.2032 12.0003H13.8768V9.74914C13.8768 8.79874 14.3424 7.87714 15.8304 7.87714H17.3424V4.92514C17.3424 4.92514 15.9696 4.68994 14.6544 4.68994C11.9136 4.68994 10.1232 6.35074 10.1232 9.36034V12.0003H7.0752V15.4707H10.1232V23.8563C10.7376 23.9523 11.3616 24.0003 12 24.0003C12.6384 24.0003 13.2624 23.9523 13.8768 23.8563V15.4707H16.6704Z" fill="white"/>
    </svg>
    
  )
}

export default IcnFb
