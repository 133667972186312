import React, { useMemo } from "react"

import PropTypes from "prop-types"
import classNames from 'classnames/bind'
import styles from '@styles/components/btn_detail.module.scss'

import TransitionAnimationLink from '@comp/transition_animation_link'
import IcnTriangle from '@comp/atom/icn_triangle'

const cx = classNames.bind(styles);
const propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  isTxtLink: PropTypes.bool,
  className: PropTypes.string
}

const BtnDetail = ({
  children,
  to,
  href,
  target,
  isTxtLink,
  className
}) => {
  const classNameWrap = cx({
    wrap: true,
    isTxtLink: isTxtLink,
    [className]: className
  })
  const blank = {
    target: target,
    rel: target === '_blank' ? 'noopener noreferrer' : null
  }

  const innerElm = useMemo( () => {
    return (
      <>
        <span className={styles.inner}>
          <span className={styles.label}>{children}</span>
          <span className={styles.icn}><IcnTriangle /></span>
        </span>
      </>
    )
  },[children])

  return (
    <>
      {
        to ? (
          <TransitionAnimationLink 
            to={to} 
            className={classNameWrap}
          >
            {innerElm}
          </TransitionAnimationLink>
        ) : (
          <a 
            href={href}
            className={classNameWrap}
            {...blank}
          >
            {innerElm}
          </a>
        )
      }
    </>
  )
}

BtnDetail.propTypes = propTypes
export default BtnDetail
